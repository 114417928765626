<template>
    <div v-loading="loading" element-loading-text="拼命加载中">
        <div class="center-title">
            <h1>{{ article.title }}</h1>
        </div>
        <div v-html="article.html" class="center-content"></div>
    </div>
</template>

<script>
import { getRestful } from "../api";

export default {
    name: "newsDetail",
    created() {
        this.newsId = this.$route.params.newsId;
        getRestful("/news/detail", this.newsId).then((res) => {
            document.title = res.data.title
            this.article = res.data;
            this.loading = false;
        });
    },
    data() {
        return {
            newsId: "",
            article: {
                title: "",
                html: "",
            },
            loading: true,
        };
    },
};
</script>

<style>
.center-title {
    text-align: center;
    /* 页面标题水平居中 */
}

.center-content {
    text-align: left;
    /* 文章内容向左对齐 */
    margin: 0 auto;
    /* 文章内容整体居中 */
    max-width: 800px;
    /* 如果需要，可以设置最大宽度以限制内容宽度 */
}
</style>