<template>
    <header>
        <h1><router-link to="/">Taoco Blog</router-link></h1>
        <nav>
            <el-menu @select="handleSelect" mode="horizontal" background-color="#333" text-color="#0070c9"
                active-text-color="#0070c9">
                <el-menu-item index="/">Home</el-menu-item>
                <el-menu-item index="/voaLearnEnglish">VoaLearnEnglish</el-menu-item>
                <el-submenu index="/user/dashboard">
                    <template slot="title">Dashboard</template>
                    <el-menu-item index="/user/dashboard/reminder">Reminder</el-menu-item>
                    <el-menu-item index="/user/dashboard/blog-list">Blogs</el-menu-item>
                    <el-menu-item index="/user/dashboard/blog-editor">BlogEditor</el-menu-item>
                    <el-menu-item index="/user/dashboard/schedule">Schedule</el-menu-item>
                    <el-menu-item index="/user/dashboard/vocabulary">Vocabulary</el-menu-item>
                    <el-menu-item index="/user/dashboard/youtube">Youtube</el-menu-item>
                    <el-menu-item index="/user/dashboard/bookDetail">BookDetail</el-menu-item>
                    <!-- <el-menu-item index="/user/dashboard/stock">Stock</el-menu-item> -->
                    <!-- <el-menu-item index="/user/dashboard/tt">Tt</el-menu-item> -->
                    <el-menu-item index="/user/dashboard/news-list">News</el-menu-item>
                    <!-- <el-menu-item index="/user/dashboard/chat">Chats</el-menu-item> -->
                    <!-- <el-menu-item index="/user/dashboard/user-config">Users</el-menu-item> -->
                    <el-menu-item index="/user/dashboard/image-gallery">Images</el-menu-item>
                    <el-menu-item index="/user/dashboard/magazine">Magazine</el-menu-item>
                    <!-- <el-menu-item index="/user/dashboard/logout">Logout</el-menu-item> -->
                </el-submenu>
            </el-menu>
        </nav>
    </header>
</template>
  
<script>
import { get } from '../api';
export default {
    name: "Header",
    methods: {
        handleSelect(keyPath) {
            if ('/user/dashboard/logout' === keyPath) {
                get('/user/logout').then(() => {
                    localStorage.removeItem('token')
                    this.$router.push('/user/login');
                });
            } else {
                this.$router.push(keyPath)
            }
        },
    },
}
</script>
  
<style scoped></style>