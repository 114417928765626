<template>
  <div id="app">
    <Header class="myHeader" />
    <router-view></router-view>
    <Footer class="myFooter" />
  </div>
</template>

<script>
import Header from './base/Header'
import Footer from './base/Footer'
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
/* 通用样式 */

body {
  font-size: 16px;
  line-height: 2.0;
}

a {
  color: #0070c9;
  text-decoration: none;
}

/* 头部 */

.myHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.myHeader h1 {
  margin: 0;
}

.myHeader nav {
  padding-right: 20px;
}

.myHeader nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.myHeader nav li {
  display: inline-block;
  margin-right: 20px;
}

/* 底部 */

.myFooter {
  margin-top: 5%;
  background-color: #eee;
  text-align: center;
  padding: 20px;
}

/* Hiding when print */
@media print {
  .audio-player {
    display: none;
  }

  .myHeader {
    display: none;
  }

  .myFooter {
    display: none;
  }
}
</style>
