<template>
    <div>
        <div style="margin-top: 20px">
            <el-button @click="batchDelete()">批量删除</el-button>
        </div>
        <el-table v-loading="loading" element-loading-text="Loading..." @selection-change="handleSelectionChange"
            :data="tableData" height="1080" border style="width: 100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column label="Actions">
                <template slot-scope="scope">
                    <router-link :to="`/blog/${scope.row.url}`" target="_blank">
                        <el-button size="small">View</el-button>
                    </router-link>
                    <el-button @click="editItem(scope.row)" size="small">Edit</el-button>
                    <el-button @click="changeStatus(scope.row.id)" size="small">ChangeStatus</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="Title" width="180">
            </el-table-column>
            <el-table-column prop="status" label="Status" width="80">
            </el-table-column>
            <el-table-column prop="author" label="Author" width="80">
            </el-table-column>
            <el-table-column prop="dynamic_tags" label="Tag">
                <template slot-scope="scope">
                    <el-tag :key="tag" v-for="tag in scope.row.dynamic_tags">
                        {{ tag }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="dynamic_cates" label="Category">
                <template slot-scope="scope">
                    <el-tag :key="cate" v-for="cate in scope.row.dynamic_cates">
                        {{ cate }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="update_time" label="updateTime" width="180">
            </el-table-column>
            <el-table-column prop="create_time" label="createTime" width="180">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { post, getRestful } from '../../api'
export default {
    name: "BlogList",
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            loading: true
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.loading = true
            post('/blog/list', {}).then(response => {
                this.tableData = response.data
                this.loading = false
            })
        },
        editItem(item) {
            this.$router.push({
                name: "admin-dashboard-blog-editor",
                params: item
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        batchDelete() {
            const blogIdList = this.multipleSelection.map(obj => obj.id)
            post('/blog/delete', blogIdList).then(() => {
                this.loadData()
                this.$message.success('批量删除成功！');
            }).catch(() => {
                this.$message.error('批量删除失败！');
            })
        },
        changeStatus(blogId) {
            getRestful('/blog/change-status', blogId).then(() => {
                this.$message.success('Change Status Successfully!');
                this.loadData()
            })
        }
    }
}
</script>

<style></style>