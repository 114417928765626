<template>
  <div class="blog-page">
    <div v-loading="blogListLoading" class="blog-list">
      <article :key="blog.url" v-for="blog in blogList" class="blog-item">
        <router-link :to="`/blog/${blog.url}`">
          <!-- 添加封面图片 -->
          <img v-if="blog.cover_image_name" :src="blog.cover_image_name" :alt="blog.cover_image_name"
            class="cover-image" />
          <h2 class="article-headline">{{ blog.title }}</h2>
        </router-link>
        <div class="blog-content">
          <span v-html="truncateText(blog.content)"></span>
          <router-link :to="`/blog/${blog.url}`" class="read-more" v-if="shouldShowReadMore(blog.content)">
            <span class="read-more-text">Read More »</span>
          </router-link>
        </div>
      </article>
    </div>
    <div class="sidebar">
      <div class="sidebar-item">
        <h3>Categories</h3>
        <el-table v-loading="cateListLoading" :show-header="false" :data="categories">
          <el-table-column fixed prop="name" />
          <el-table-column fixed prop="amount" />
        </el-table>
      </div>
      <div class="sidebar-item">
        <h3>Tag</h3>
        <el-table v-loading="tagListLoading" :show-header="false" :data="tags">
          <el-table-column fixed prop="name" />
          <el-table-column fixed prop="amount" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../api'
export default {
  name: 'Home',
  data() {
    return {
      blogListLoading: true,
      cateListLoading: true,
      tagListLoading: true,
      categories: [],
      tags: [],
      blogList: [],
      // websitePrefix: 'http://taocoweb.top/static/image/'
    }
  },
  created() {
    post('/blog/list', { status: 1 }).then(res => {
      this.blogListLoading = false
      this.blogList = res.data
    })

    get('/blog/dynamicCates/list').then(res => {
      this.cateListLoading = false
      this.categories = res.data
    })

    get('/blog/dynamicTags/list').then(res => {
      this.tagListLoading = false
      this.tags = res.data
    })
  },
  methods: {
    truncateText(text) {
      const maxLength = 100;
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    },
    shouldShowReadMore(text) {
      const maxLength = 100;
      return text.length > maxLength;
    },
  },
}
</script>

<style>
.blog-page {
  display: flex;
  justify-content: space-between;
  /* 将子元素分散排列 */
  align-items: flex-start;
  /* 垂直居上对齐 */
}

.blog-list {
  margin-left: 10%;
  width: 55%;
}

.blog-item {
  border: 1px solid #ccc;
  margin: 24px 0;
  /* 上下外边距控制博客项之间的间距 */
}

.article-headline {
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 22px;
}

.blog-content {
  margin-left: 10px;
  margin-bottom: 10px;
}

.read-more {
  color: rgb(43, 109, 216);
}

.read-more-text {
  white-space: nowrap;
  /* 防止文本换行 */
}

.sidebar {
  margin-top: 5%;
  margin-right: 5%;
  width: 25%;
}

.sidebar-item {
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
}

.sidebar-item h3 {
  margin-top: 0;
}

.cover-image {
  width: 100%;
  max-height: 300px;
  /* 设置最大高度，防止图片过大 */
  object-fit: cover;
  /* 保持图片比例并裁剪以适应容器 */
}
</style>