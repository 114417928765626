<template>
    <div>
        <div class="search-box">
            <el-button @click="updateSourceList" type="primary">update voa</el-button>
            <el-button @click="getVoaSourceList" type="primary">flash list</el-button>
        </div>
        <div class="video-list">
            Source List :
            <el-table v-loading="loading" :data="voaList">
                <el-table-column label="Actions" width="150">
                    <template slot-scope="scope">
                        <router-link :to="`/voa/${scope.row.id}`" target="_blank"><el-button
                                size="small">View</el-button></router-link>
                    </template>
                </el-table-column>
                <el-table-column label="title" prop="title"></el-table-column>
                <el-table-column label="link" prop="link"></el-table-column>
                <el-table-column label="into_db_time" prop="into_db_time"></el-table-column>
            </el-table>
        </div>
    </div>
</template>
  
<script>
import { get } from '../api'

export default {
    name: 'VoaLearnEnglish',
    data() {
        return {
            loading: true,
            voaList: [],
            websitePrefix: 'http://taocoweb.top/static/youtube/'
        };
    },
    created() {
        this.getVoaSourceList()
    },
    methods: {
        getVoaSourceList() {
            this.loading = true,
                get('/voa/list').then(res => {
                    this.loading = false
                    this.voaList = res.data
                })
        },
        updateSourceList() {
            get('/voa/update').then(() => {
                this.$message.success('开始更新VOA资源');
            })
        }
    },
};
</script>
  
<style>
.search-box {
    margin-top: 3%;
    margin-left: 1%;
}

.horizontal-form .el-form-item {
    display: inline-block;
    width: 30%;
}
</style>
