<template>
    <div v-loading="loading" element-loading-text="拼命加载中">
        <div class="center-title">
            <h1>{{ voa.title }}</h1>
        </div>
        <div class="center-content">
            <vue-markdown :source="voa.html"></vue-markdown>
            <h2>Q & A</h2>
            <div style="display: flex; align-items: center;">
                <span style="margin-right: 10px;font-weight: bold;">Copy Prompt:</span>
                <el-button class="copy-btn" type="text" icon="el-icon-copy-document"></el-button>
            </div>
            <div style="display: flex; align-items: center;">
                <span style="margin-right: 10px;font-weight: bold;">Import QA:</span>
                <el-input v-model="voa.qas" clearable :disabled="qaInputDisable" placeholder="Nothing here"
                    style="width: 200px;margin-right: 10px;"></el-input>
                <el-button type="text" :icon="qaButtonIcon" @click="editQaInput" style="margin-right: 10px;"></el-button>
                <el-button v-if="showCancelEditQaInput" type="text" icon="el-icon-close"
                    @click="cancelEditQaInput"></el-button>
            </div>
            <div class="qaList" v-for="(qa, index) in voa.qas" :key="index">
                <div style="display: flex;">
                    <h3 style="margin-right: 10px;">{{ qa.q }}</h3>
                    <el-button type="text" style="margin-right: 10px;"
                        :icon="qa.showInputAnswer ? 'el-icon-check' : 'el-icon-edit'"
                        @click="editAInput(index)"></el-button>
                    <el-button type="text" :icon="qa.showGPTAnswer ? 'el-icon-close' : 'el-icon-view'"
                        @click="toggleGPTAnswer(index)"></el-button>
                </div>
                <div v-if="qa.showInputAnswer" style="display: flex;">
                    <span style="font-weight: bold;">My Answer: </span>
                    <el-input style="width: 600px;margin-left: 10px;" type="textarea" autosize placeholder="Plz Input"
                        v-model="qa.my_a"></el-input>
                </div>
                <div v-if="!qa.showInputAnswer && qa.my_a">
                    <span style="font-weight: bold;">My Answer: </span>
                    <span>{{ qa.my_a }}</span>
                </div>
                <div v-if="qa.showGPTAnswer">
                    <span style="font-weight: bold;">ChatGPT Answer: </span>
                    {{ qa.a }}
                </div>
            </div>
        </div>
        <div class="audio-player">
            <audio ref="audioPlayer" :src="voa.audio_file_name" controls class="custom-audio"></audio>
            <div class="audio-controls">
                <button @click="backward">倒退</button>
                <button @click="togglePlay">
                    {{ isPlaying ? '暂停' : '播放' }}
                </button>
                <button @click="forward">前进</button>
                <button @click="toggleLoop">
                    {{ isLooping ? '关循环' : '开循环' }}
                </button>
                <select id="speedSelect" v-model="selectedSpeed" @change="changePlaybackSpeed">
                    <option value="0.5">0.5x</option>
                    <option value="0.75">0.75x</option>
                    <option value="1">1x</option>
                    <option value="1.25">1.25x</option>
                    <option value="1.5">1.5x</option>
                </select>
                <el-checkbox-group v-model="isTrack">
                    <el-checkbox label="跟踪"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>
  

<script>
import { getRestful, post } from '../api';
import VueMarkdown from 'vue-markdown';
import ClipboardJS from 'clipboard';

export default {
    name: 'VoaDetail',
    created() {
        this.voaId = this.$route.params.voaId;
        getRestful('/voa/detail', this.voaId).then(res => {
            document.title = res.data.title;
            this.voa = res.data;
            this.voa.audio_file_name = this.websitePrefix + this.voa.audio_file_name
            this.loading = false;
            // Set audio source dynamically
            this.$refs.audioPlayer.src = this.voa.audio_file_name;

            // 设置定时器监听音频播放时间
            setInterval(() => {
                this.currentTime = this.$refs.audioPlayer.currentTime;
                this.highlightText();
            }, 500);
        });
    },
    components: {
        VueMarkdown,
    },
    data() {
        return {
            voaId: '',
            voa: {},
            loading: true,
            isPlaying: false,
            isLooping: false,
            websitePrefix: 'http://taocoweb.top/static/voa/audio/',
            currentTime: 0,
            selectedSpeed: "1", // 默认播放速度为1x
            isTrack: false,
            qaButtonIcon: 'el-icon-edit',
            qaInputDisable: true,
            showCancelEditQaInput: false,
            showGPTAnswer: false,
            clipboard: null,
        };
    },
    mounted() {
        const audio = this.$refs.audioPlayer;
        audio.addEventListener('playing', this.handlePlaying);
        audio.addEventListener('pause', this.handlePause);

        window.addEventListener('keydown', this.handleKeyDown);

        this.clipboard = new ClipboardJS('.copy-btn', {
            text: () => {
                var tempDiv = document.createElement('div');
                tempDiv.innerHTML = this.voa.html;
                var targetTag = tempDiv.querySelector('.wsw__h2');
                while (targetTag.nextSibling) {
                    targetTag.parentNode.removeChild(targetTag.nextSibling);
                }
                var content = tempDiv.textContent || tempDiv.innerText
                const prompt = "请你根据上述这篇文章，并模仿雅思出题方式，出几道题。要求：1、你不用给我翻译，也不用给我解释文章内容，我要的直接是英文问题。2、我需要你同时提供问题的答案。3、返回格式如下：[{\"q\":\"question_1_xxxxx?\",\"my_a\":\"\",\"a\":\"answer_1_xxxxx.\",\"showGPTAnswer\":false,\"showInputAnswer\":false},{\"q\":\"question_2_xxxxx?\",\"my_a\":\"\",\"a\":\"answer_2_xxxxx.\",\"showGPTAnswer\":false,\"showInputAnswer\":false},,,]"
                return content + prompt;
            }
        });

        this.clipboard.on('success', (e) => {
            this.$message.success('复制成功！');
            e.clearSelection();
        });

        this.clipboard.on('error', () => {
            this.$message.error('复制失败！');
        });
    },
    destroyed() {
        window.removeEventListener('keydown', this.handleKeyDown);
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    },
    methods: {
        toggleGPTAnswer(index) {
            this.voa.qas[index].showGPTAnswer = !this.voa.qas[index].showGPTAnswer;
        },
        editAInput(index) {
            this.voa.qas[index].showInputAnswer = !this.voa.qas[index].showInputAnswer;
            if (this.voa.qas[index].showInputAnswer === false) {
                this.save_qa()
            }
        },
        editQaInput() {
            if (this.qaButtonIcon === 'el-icon-check') {
                this.voa.qas = JSON.parse(this.voa.qas);
                this.save_qa()
            }
            this.qaButtonIcon = 'el-icon-check'
            this.qaInputDisable = false
            this.showCancelEditQaInput = true
        },
        save_qa() {
            this.voa.qas.forEach((qa) => {
                qa.showInputAnswer = false;
                qa.showGPTAnswer = false;
            });
            post('/voa/saveQa', { 'voa_id': this.voaId, 'qas': this.voa.qas }).then(() => {
                this.$message.success('Save Successfully!');
                // flush page
                location.reload()
            })
        },
        cancelEditQaInput() {
            this.qaButtonIcon = 'el-icon-edit'
            this.qaInputDisable = true
            this.showCancelEditQaInput = false
        },
        handleKeyDown(event) {
            switch (event.key) {
                case 'ArrowLeft':
                    this.backward();
                    break;
                case 'ArrowRight':
                    this.forward();
                    break;
                default:
                    break;
            }
        },
        togglePlay() {
            this.isPlaying = !this.isPlaying;
            const audio = this.$refs.audioPlayer;
            if (this.isPlaying) {
                audio.play();
            } else {
                audio.pause();
            }
        },
        backward() {
            const audio = this.$refs.audioPlayer;
            audio.currentTime -= 5;
        },
        forward() {
            const audio = this.$refs.audioPlayer;
            audio.currentTime += 5;
        },
        toggleLoop() {
            this.isLooping = !this.isLooping;
            const audio = this.$refs.audioPlayer;
            audio.loop = this.isLooping;
        },
        handlePlay() {
            this.isPlaying = true;
        },
        handlePause() {
            this.isPlaying = false;
        },
        highlightText() {
            const paragraphs = document.querySelectorAll('.center-content p[read="1"]');
            const totalTime = this.getTotalTime(paragraphs);

            let currentTime = 0;
            let highlightedParagraph = null; // 跟踪当前高亮的段落

            paragraphs.forEach((paragraph) => {
                const paragraphTime = (paragraph.textContent.length / totalTime) * this.$refs.audioPlayer.duration;

                if (this.currentTime >= currentTime && this.currentTime < currentTime + paragraphTime) {
                    paragraph.classList.add('highlight');
                    highlightedParagraph = paragraph; // 更新当前高亮的段落
                } else {
                    paragraph.classList.remove('highlight');
                }

                currentTime += paragraphTime;
            });

            // 如果当前高亮的段落不可见，将其滚动到视图中央
            if (highlightedParagraph && this.isTrack) {
                const rect = highlightedParagraph.getBoundingClientRect();
                if (rect.top < 0 || rect.bottom > window.innerHeight) {
                    highlightedParagraph.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
        },

        getTotalTime(paragraphs) {
            return Array.from(paragraphs).reduce((total, paragraph) => {
                return total + (paragraph.textContent.length || 1); // Ensure a minimum of 1 for empty paragraphs
            }, 0);
        },

        changePlaybackSpeed() {
            const audio = this.$refs.audioPlayer;
            audio.playbackRate = parseFloat(this.selectedSpeed);
        },
    },
};
</script>


<style>
.center-title {
    text-align: center;
    /* 页面标题水平居中 */
}

.center-content {
    text-align: left;
    /* 文章内容向左对齐 */
    margin: 0 auto;
    /* 文章内容整体居中 */
    max-width: 800px;
    /* 如果需要，可以设置最大宽度以限制内容宽度 */
}

.audio-player {
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* Adjust the value to control the roundness */
}


.audio-controls {
    display: flex;
    gap: 10px;
    /* Adjust the gap as needed */
}

.highlight {
    background-color: yellow;
    /* 设置高亮背景色 */
}

.custom-audio {
    /* 设置播放器的宽度 */
    width: 100%;
    /* 添加一些间距和边框 */
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* 添加背景颜色和阴影效果 */
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>