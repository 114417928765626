<template>
    <div class="login-container">
        <el-form ref="form" :model="form" class="login-form">
            <el-form-item label="账号">
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onLogin">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import md5 from 'js-md5';
import { post } from '../api.js';

export default {
    name: "Login",
    data() {
        return {
            form: {
                username: '',
                password: ''
            },
            salt: 'TaocoWebInvestBlog'
        }
    },
    methods: {
        onLogin() {
            this.form.password = md5(this.salt + this.form.password)
            post('/user/login', this.form).then(res => {
                if (res.success == true) {
                    localStorage.setItem('token', res.data.access_token)
                    const post = localStorage.getItem('postRoute')
                    const postRouteParams = localStorage.getItem('postRouteParams')
                    const curr = localStorage.getItem('preRoute')
                    if (post == null && curr == null) {
                        this.$router.push({ path: '/user/dashboard' })
                    } else {
                        if (post != null) {
                            let params = null
                            if (postRouteParams != null) {
                                params = JSON.parse(postRouteParams)
                            }
                            // 传递param只能是name
                            this.$router.push({ name: post, params: params });
                            localStorage.removeItem('postRoute')
                            localStorage.removeItem('postRouteParams')
                        } else {
                            this.$router.push({ path: curr });
                            localStorage.removeItem('preRoute')
                        }
                    }
                } else {
                    this.$message.error('密码错误！');
                }
            });
        }
    }
}
</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.login-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>