<template>
    <div v-loading="loading">Title:
        <el-input v-model="blog.title"></el-input>
        Content:
        <v-md-editor height="600px" v-model="blog.markdown_text" @save="onSave">
        </v-md-editor>
        Cover:
        <el-input v-model="blog.cover_image_name" clearable placeholder="Enter URL / Base64 Image"
            style="max-width: 300px;"></el-input>
        <el-button @click="chooseCover">Choose Cover</el-button>
        <div>
            <img v-show="blog.cover_image_name" :src="blog.cover_image_name" width="50px">
        </div>
        <ImageSelectDialog ref="imageSelectDialog" />
        Author: <el-input v-model="blog.author" :disabled="true" style="width: 200px"></el-input>
        <div>
            Tag:
            <el-select v-model="blog.dynamic_tags" clearable multiple filterable allow-create default-first-option
                placeholder="请选择或输入Tags">
                <el-option v-for="item in tags" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </div>
        <div>Category:
            <el-select v-model="blog.dynamic_cates" clearable multiple filterable allow-create default-first-option
                placeholder="请选择或输入Cates">
                <el-option v-for="item in cates" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </div>
        <div>
            Status: <el-switch v-model="blog.status" active-text="Publish" inactive-text="Don't Publish" />
        </div>
        <div>
            <el-button @click="onSave">Save</el-button>
            <el-button @click="onSubmit">Submit</el-button>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'; // 导入整个 lodash 库
import { post, get } from '../../api'
import ImageSelectDialog from "./ImageSelectDialog.vue";

export default {
    name: 'BlogEditor',
    components: { ImageSelectDialog },
    data() {
        return {
            blog: {
                id: null,
                title: '',
                author: 'Mark Chan',
                markdown_text: "# Ok, Let's Start!",
                dynamic_tags: [],
                dynamic_cates: [],
                cover_image_name: '',
                status: true,
            },
            tags: [],
            cates: [],
            loading: false,
            unsavedChanges: false,
            debouncedSave: null
        }
    },
    watch: {
        'blog.markdown_text': function (newVal, oldVal) {
            if (newVal !== oldVal && oldVal !== "# Ok, Let's Start!") {
                this.unsavedChanges = true;
            }
        },
    },
    mounted() {
        // 添加页面可见性变化监听
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
        // 添加窗口失去焦点监听
        window.addEventListener("blur", this.handleBlur);
    },
    created() {
        // 创建一个防抖的保存函数，saveArticle在 300 毫秒内只会执行一次，无论它被调用了多少次。
        this.debouncedSave = _.debounce(this.onSave, 300);
        if (Object.keys(this.$route.params).length !== 0) {
            this.blog = this.$route.params
            this.blog.status = this.blog.status === 1 ? true : false
        }

        get('/blog/list/dynamicTags').then(res => {
            this.tagListLoading = false
            this.tags = res.data
        })

        get('/blog/list/dynamicCates').then(res => {
            this.cateListLoading = false
            this.cates = res.data
        })
    },
    beforeDestroy() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        window.removeEventListener("blur", this.handleBlur);
        // 取消可能正在进行的防抖操作
        if (this.debouncedSave) {
            this.debouncedSave.cancel();
        }
    },
    methods: {
        onSubmit() {
            this.loading = true
            this.onSave().then(flag => {
                if (flag) {
                    this.unsavedChanges = false;
                    this.$router.push('/user/dashboard/blog-list')
                }
            })
        },
        onSave() {
            this.loading = true;
            this.blog.status = this.blog.status ? 1 : 0
            return new Promise(() => {
                post('/blog/add', this.blog).then(res => {
                    if (res.success == true) {
                        this.blog.id = res.data
                    }
                    this.loading = false;
                    this.$message.success('保存成功！');
                    this.unsavedChanges = false;
                    this.blog.status = this.blog.status === 1 ? true : false
                }).catch(() => {
                    this.loading = false;
                    this.$message.error('保存失败！');
                });
            });
        },
        chooseCover() {
            this.$refs.imageSelectDialog.open();
        },
        handleVisibilityChange() {
            if (document.hidden && this.debouncedSave) {
                this.debouncedSave();
            }
        },
        handleBlur() {
            if (this.debouncedSave) {
                this.debouncedSave();
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.unsavedChanges) {
            const confirmMessage = 'You have unsaved changes. Do you really want to leave?';
            if (window.confirm(confirmMessage)) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
}
</script>

<style></style>