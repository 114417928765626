import Vue from 'vue'
import App from './App.vue'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import store from './store'
import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

//引入插件
import VueRounter from 'vue-router';
//引入路由配置
import router from './router/index.js'	//因为是index文件，所以也可以不加
//引入element-ui完成的组件库
import ElementUI from 'element-ui';
//引入element-ui（全部）样式
import 'element-ui/lib/theme-chalk/index.css';
//应用element-ui（因为element-ui是vue的一个插件）


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueMarkdownEditor);
//使用插件
Vue.component(VueRounter);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
