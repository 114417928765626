<template>
    <div v-loading="loading" element-loading-text="拼命加载中">
        <div class="center-title">
            <h1>{{ blog.title }}</h1>
        </div>
        <div class="info" v-show="blog.author">
            <span>作者：{{ blog.author }}</span>
            <span>时间：{{ blog.create_time | formatDate('yyyy-MM-dd') }}</span>
            <span v-show="editable"><button @click="gotoEdit()">编辑文章</button></span>
            <hr>
        </div>
        <div class="center-content">
            <div v-html="content" class="markdown-body html-content"></div>
        </div>
    </div>
</template>

<script>
import hljs from 'highlight.js'
import 'highlight.js/styles/vs2015.css'
import { marked } from 'marked'
import { getRestful } from '../api'
export default {
    name: 'blogDetail',
    data() {
        return {
            uri: "",
            blog: {
                title: "",
                markdown_text: "",
                author: "",
                create_time: ""
            },
            loading: true,
            editable: false
        }
    },
    filters: {
        formatDate(value) {
            var dt = new Date(value);
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2, '0');
            let date = dt.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${date}`;
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        content() {
            var reg = new RegExp("<pre", "g")
            var content = marked(this.blog.markdown_text)
            var str = content.replace(reg, `<pre class="line-numbers hljs"`)
            return str
        }
    },
    watch: {
        'blog.markdown_text'() {
            this.$nextTick(() => {
                setTimeout(() => {
                    hljs.highlightAll()
                }, 0)
            })
        }
    },
    methods: {
        getData() {
            this.uri = this.$route.params.uri;
            getRestful('/blog/detail', this.uri).then(res => {
                document.title = res.data.title
                this.blog = res.data
                this.loading = false
            })
            this.checkEditable()
        },
        checkEditable() {
            let token = localStorage.getItem('token')
            if (token) {
                this.editable = true
            }
        },
        gotoEdit() {
            this.$router.push({
                name: "admin-dashboard-blog-editor",
                params: this.blog
            })
        }
    }
}
</script>

<style>
.center-title {
    text-align: center;
    /* 页面标题水平居中 */
}

.center-content {
    text-align: left;
    /* 文章内容向左对齐 */
    margin: 0 auto;
    /* 文章内容整体居中 */
    max-width: 900px;
    /* 如果需要，可以设置最大宽度以限制内容宽度 */
}

.info {
    text-align: right;
    max-width: 900px;
    /* 调整此值以改变info div的最大宽度 */
    margin: 0 auto;
    /* 这会使div水平居中 */
    color: grey;
    font-size: 15px;
}

.info span {
    display: inline-block;
    margin: 0 20px;
    /* 在span之间添加一些间距 */
}

.info hr {
    width: 100%;
    /* 确保hr横跨整个info div */
    margin: 0;
    /* 在hr上下添加一些间距 */
}

pre {
    font-size: medium;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>