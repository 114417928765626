// store.js
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  socket: null, // 用于存储 Socket 连接实例
};

const mutations = {
  setSocket(state, socket) {
    state.socket = socket;
  },
};

export default new Vuex.Store({
  state,
  mutations,
});
